<template>
  <div class="navig-box">
    <ul class="navig-list">
      <li class="navig-item" v-for="item in result" :key="item.id">
        <el-row :gutter="26">
          <el-col :md="6">
              <img v-if="item.theLocal" :src="`/jeecg-boot/${item.theLocal}`" class="imgleft"/>
                <img v-else src="@/assets/image/main/zanwutp.png" alt="" class="imgleft"/>
          </el-col>
          <el-col :md="18">
            <div class="navig-info">
              <router-link :to="{ path: detailurl, query: { id: item.id } }">
                <h4 class="title">{{ item.theTitle }}</h4>
                <p class="desc">{{ item.theInfo }}</p>
                <p class="time">
                  {{ item.createTime.substring(0, 10) }}
                  <i class="fa fa-long-arrow-right arrow lr"></i>
                </p>
              </router-link>
            </div>
          </el-col>
        </el-row>
      </li>
    </ul>
    <Pagi
      :total="pagi.total"
      :current="pagi.current"
      :sizes="pagi.size"
      @pagicurrent="togglePagi"
    />
  </div>
</template>

<script>
import Pagi from "@/components/Pagi";
import { getAction } from "@/api/manage";
export default {
  props: ["urllist", "detailurl"],
  components: {
    Pagi,
  },
  created() {
    this.loadData(1);
  },
  data() {
    return {
      result: [],
      pagi: {
        total: 0,
        current: 1,
        size: 10,
      },
      url: {
        list: this.urllist,
      },
    };
  },
  methods: {
    loadData(value) {
      getAction(this.url.list, {
        pageNo: value,
      }).then((res) => {
        if (res.success) {
          this.result = res.result.records;
          this.pagi.total = res.result.total;
          this.pagi.current = res.result.current;
          this.pagi.size = res.result.size;
        }
      });
    },
    togglePagi(value) {
      this.loadData(value);
    },
  },
};
</script>

<style lang="scss" scoped>
.navig-box {
  margin-bottom: 50px;
  padding: 0 18px 0 18px;
  .navig-list {
    list-style: none;
    min-height: 400px;
    .navig-item {
      border-bottom: 1px dashed #dbdbdb;
      padding: 25px 0;
      .imgleft {
        width: 100%;
        height: 184px;
      }
      .navig-info {
        padding: 10px 0 9px;
        .title {
          font-size: 24px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          transition: all 0.5s;
        }
        .desc {
          margin: 20px 0;
          height: 78px;
          font-size: 14px;
          line-height: 26px;
          color: #999999;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
          text-align: justify;
        }
        .time {
          color: #999999;
          font-size: 14px;
          .arrow {
            font-size: 20px;
            transition: all 0.5s;
          }
        }
      }
      &:hover {
        .title {
          color: #6EB400;
        }
        .time {
          .arrow {
            color: #6EB400;
          }
        }
      }
    }
  }
}
</style>
